import { printUrl, fprint } from '../../popups'
import utils from '../../utils'

export const OformlenieFormComponent = {
  template: require('./oformlenie-form.component.html'),
  styles: [require('./oformlenie-form.component.scss')],
  bindings: {
    pid: '<',
    fid: '<',
    val: '<',
  },
  /** @ngInject */
  controller: function (
    $q,
    $http,
    uchetPechatService,
    firmsService,
    uchetService,
    keensService,
    zaysService,
    uslModal,
    uchetServiceApiV2,
    uslugi2ServiceApiV2
  ) {
    const $ctrl = this
    let modal = null

    $ctrl.currency = [
      { id: 'rur', name: 'R' },
      { id: 'usd', name: '$' },
      { id: 'eur', name: 'E' },
    ]
    $ctrl.kurses = {
      rur: 1,
      usd: null,
      eur: null,
    }
    $ctrl.kurs = 1

    $ctrl.up = null
    $ctrl.up0 = null
    $ctrl.uchet = null
    $ctrl.zay = null // идентификатор
    $ctrl.zayObj = null // объект
    $ctrl.uslTypes = null
    $ctrl.uslMinus = true
    $ctrl.destUslNum = 1
    $ctrl.upForm = null
    $ctrl.oformlenieForm = null
    $ctrl.upTableCtrl = null
    $ctrl._itogo = 0.0
    $ctrl.upTableOptions = {
      headers: {
        num: '№',
        text: 'Наименование',
        voz: 'Вознаграждение',
        izm: 'Ед. изм.',
        count: 'Кол-во',
        nds_str: 'НДС, %',
        summ: 'Сумма, у.е.',
      },
      trackSelection: true,
      tablesorterOptions: {
        sortList: [[0, 0]],
      },
    }
    $ctrl.onUPTableInit$ = $q.defer()

    Object.defineProperties($ctrl, {
      upIsClean: {
        get: () => JSON.stringify($ctrl.up) === JSON.stringify($ctrl.up0),
      },
      ready: {
        get: () => {
          return (
            !!$ctrl.pid && !!$ctrl.fid && !!$ctrl.uchet && !!$ctrl.upTableCtrl
          )
        },
      },
      upActionsAvailable: {
        get: () => {
          // да, если учет-печать сущеcтвует и форма не редактировалась
          return !!$ctrl.up && !!$ctrl.up.id && $ctrl.upIsClean
        },
      },
      printZayAvailable: {
        get: () => {
          return (
            $ctrl.upActionsAvailable &&
            !!$ctrl.uchet.inner &&
            $ctrl.uchet.inner === '1'
          )
        },
      },
      printZayVisible: {
        get: () => {
          return !!$ctrl.zayObj && $ctrl.zayObj.type !== 'tm'
        },
      },
      printSchetDisabled: {
        get: () => {
          if (!$ctrl.zayObj) {
            return 'Пустая заявка'
          }
          if (!$ctrl.zayObj.zaid) {
            return 'В заявке не указан заказчик'
          }
          return ''
        },
      },
      saveUPAvailable: {
        get: () => {
          return $ctrl.oformlenieForm.$valid && !$ctrl.upIsClean
        },
      },
      addUslAvailable: {
        get: () => {
          return (
            !!$ctrl.upTableCtrl &&
            $ctrl.upTableCtrl.nrows < 10 &&
            !!$ctrl.up &&
            !!$ctrl.up.type
          )
        },
      },
      addVoznAvailable: {
        get: () => {
          return (
            !!$ctrl.upTableCtrl &&
            $ctrl.upTableCtrl.rows.find((row) => row.num === 10) === undefined
          )
        },
      },
      schetFirm: {
        get: () => {
          if (!$ctrl.firms) {
            return ''
          }
          const firm = $ctrl.firms[$ctrl.up.firm]
          if (!firm) {
            return ''
          }
          return `${firm.type} "${firm.comp.trim()}"`
        },
      },
      currencyStr: {
        get: () => {
          const curr =
            (!!$ctrl.up &&
              $ctrl.currency.find((c) => c.id === $ctrl.up.currency)) ||
            $ctrl.currency[0].name
          return curr.name
        },
      },
    })

    $ctrl.kursRecalc = () => {
      const oldcur = $ctrl.up.currency
      const newcur = $ctrl.val

      const rows = $ctrl.upTableCtrl.rows

      rows.forEach((r) => {
        r.summ = $.fn.convertCurr(
          r.summ,
          oldcur,
          $ctrl.kurses[oldcur],
          newcur,
          $ctrl.kurses[newcur]
        )
      })
      $ctrl.upTableCtrl.loadRows(rows)
      $ctrl.up.currency = $ctrl.val
    }

    $ctrl.renderRow = (row) => {
      row.nds_str = row.nds === '' ? 'н/о' : row.nds
      row.voz = row.voz === 1 ? '<i class="fa fa-check">' : ''
      row.summ = $.fn.round2(row.summ)
      return row
    }

    function commitUP(up) {
      const up1 = up || $ctrl.up
      $ctrl.up = { ...up1 }
      $ctrl.up0 = { ...up1 }
      $ctrl.up0.values = [...$ctrl.up.values]
      $ctrl.val = $ctrl.up.currency
    }

    function recalcItogo() {
      $ctrl._itogo = $ctrl.upTableCtrl.rows
        .map((row) => row.summ)
        .reduce((a, v) => a + v, 0.0)
      return $.fn.round2($ctrl._itogo)
    }

    $ctrl.$onInit = () => {
      const promises = [
        uslugi2ServiceApiV2.get(),
        firmsService.get({ kmbm__nashi: 1 }),
        $ctrl.onUPTableInit$.promise,
      ]
      return $q.all(promises).then((resps) => {
        const uslTypes = resps[0].results
        const firms = resps[1]
        const upTableCtrl = resps[2]

        $ctrl.uslTypes = uslTypes
        $ctrl.firms = firms.idlize((f) => f.kmbm)
        $ctrl.upTableCtrl = upTableCtrl

        $ctrl.$onChanges = $onChanges

        Object.defineProperties($ctrl, {
          itogo: {
            get: recalcItogo,
          },
        })

        return $ctrl.reload()
      })
    }

    function $onChanges(changes) {
      $ctrl.selected = null
      $ctrl.reload()
    }

    $ctrl.reload = () => {
      if (!$ctrl.pid || !$ctrl.fid) {
        $ctrl.uchet = null
        $ctrl.reset()
        return
      }

      const promises = [
        uchetPechatService.get({ schet: $ctrl.pid, firm: $ctrl.fid }),
        uchetService.get({ nscheta: $ctrl.pid, firm: $ctrl.fid }),
        keensService.get({ kod: $ctrl.fid }),
      ]
      return $q.all(promises).then((resps) => {
        const uchetPrints = resps[0]
        $ctrl.uchet = resps[1]
        $ctrl.allbanks = resps[2]

        if (!$ctrl.uchet.length) {
          $ctrl.reset()
          $ctrl.zay = null
          $ctrl.zayObj = null
          return
        }

        // теперь есть внешние ключи и такой счет обязательно существует
        // (и только 1), а значит, и заявка
        $ctrl.uchet = $ctrl.uchet[0]
        $ctrl.zay = $ctrl.uchet.pid

        return zaysService.get({ id: $ctrl.zay }).then((zay) => {
          $ctrl.zayObj = zay

          if (uchetPrints) {
            commitUP(uchetPrints)
            $ctrl.upTableCtrl.loadRows(
              $ctrl.up.values.filter((v) => v.count > 0)
            )
          } else {
            $ctrl.reset()
          }
        })
      })
    }

    /**
     * Сброс формы на основе счета
     */
    $ctrl.reset = () => {
      $ctrl.up = {
        bank: null,
        schet: $ctrl.pid,
        firm: $ctrl.fid,
        datapa: new Date(),
        datap: new Date(),
        datapf: new Date(),
        currency: 'rur',
        values: [],
      }

      const rows = []
      if ($ctrl.uchet) {
        rows.push({
          id: 1,
          text: '(новая услуга)',
          count: 1,
          izm: 'Усл.',
          nds: `${$ctrl.uchet.nds * 100}`,
          summ: $ctrl.uchet.summa - $ctrl.uchet.veksp,
          num: 1,
        })

        if ($ctrl.uchet.veksp) {
          rows.push({
            id: 2,
            text: 'Вознаграждение экспедитора',
            count: 1,
            izm: 'Усл.',
            nds: `${$ctrl.uchet.nds * 100}`,
            summ: $ctrl.uchet.veksp,
            num: 10,
            voz: 1,
          })
        }
      }

      $ctrl.upTableCtrl.loadRows(rows)
    }

    $ctrl.addUsl = () => {
      modal = uslModal.open({
        locals: {
          usl: {
            id: getNextUslId(), // для js-table
            count: 1,
            nds: `${$ctrl.uchet.nds * 100}`,
            izm: 'Усл.',
            summ: $ctrl.upTableCtrl.nrows === 0 ? $ctrl.uchet.summa : null,
            num: getNextNum(),
          },
          zay: $ctrl.zayObj,
          uchet: $ctrl.uchet,
          uslType: $ctrl.selectedUslType,
        },
      })
      modal.closed.then($ctrl.addOrUpdateUsl)
    }

    Object.defineProperty($ctrl, 'selectedUslType', {
      get: () => {
        return $ctrl.uslTypes.filter((ut) => ut.id === $ctrl.up.type)[0]
      },
    })

    $ctrl.addOrUpdateUsl = (usl) => {
      if (!usl) return
      if (isNewUsl(usl)) {
        $ctrl.upTableCtrl.addRow(usl)
      } else {
        $ctrl.upTableCtrl.updateRow(usl)
      }

      $ctrl.up.values = $ctrl.upTableCtrl.rows

      function isNewUsl(usl) {
        $.fn.assert(!!usl.id)
        // если id нет в таблице услуг, то она новая
        const usls = $ctrl.upTableCtrl.rows
        return !usls.map((u) => +u.id).includes(usl.id)
      }
    }

    $ctrl.addVozn = () => {
      const usl = {
        id: getNextUslId(), // для js-table
        text: 'Вознаграждение экспедитора',
        count: 1,
        nds: $ctrl.uchet.nds === '' ? '' : `${$ctrl.uchet.nds * 100}`,
        izm: 'Усл.',
        num: 10,
        summ: $ctrl.uchet.veksp,
        voz: 1,
      }

      $ctrl.upTableCtrl.addRow(usl)
      $ctrl.up.values = $ctrl.upTableCtrl.rows
    }

    function getNextUslId() {
      if ($ctrl.upTableCtrl.nrows === 0) {
        return 1
      } else {
        const maxId = Math.max(...$ctrl.upTableCtrl.rows.map((row) => row.id))
        return maxId + 1
      }
    }

    function getNextNum() {
      const nums = $ctrl.upTableCtrl.rows.map((row) => row.num)
      if (nums.length === 0) {
        return 1
      } else {
        const idxOf10 = nums.indexOf(10)
        if (idxOf10 !== -1) {
          nums.splice(idxOf10, 1)
        }
        let maxn = Math.max(...nums)
        if (nums.length === 0) {
          maxn = 0
        }
        $.fn.assert(maxn < 9, 'Достигнут предел добавления строк')
        $.fn.assert(
          Array.from(new Set(nums)).length === nums.length,
          'Есть дублирующиеся номера в строках'
        )
        return maxn + 1
      }
    }

    $ctrl.editUsl = () => {
      modal = uslModal.open({
        locals: {
          usl: $ctrl.upTableCtrl.getRowDataById($ctrl.selected),
          zay: $ctrl.zayObj,
          uchet: $ctrl.uchet,
          uslType: $ctrl.selectedUslType,
        },
      })
      modal.closed.then($ctrl.addOrUpdateUsl)
    }

    $ctrl.removeUsl = () => {
      $ctrl.upTableCtrl.removeRow($ctrl.selected)
      $ctrl.up.values = $ctrl.upTableCtrl.rows
    }

    $ctrl.save = () => {
      if (!$ctrl.pid || !$ctrl.fid) {
        return
      }
      if (!$ctrl.up.bank) {
        alert('Пожалуйста, выберите банк!')
        return
      }
      if (!$ctrl.up.type) {
        alert('Пожалуйста, выберите тип услуги!')
        return
      }

      return uchetPechatService.save($ctrl.up).then(commitUP)
    }

    $ctrl.printAct = () => {
      $ctrl.save().then((resp) => {
        uchetServiceApiV2.act($ctrl.uchet, {
          kmid: $ctrl.up.bank,
          usluga: $ctrl.up.type,
        })
      })
    }

    $ctrl.printSchet = () => {
      $ctrl.save().then((resp) => {
        getSchet('s')
      })
    }

    $ctrl.printZay = () => {
      const zay = $ctrl.zayObj
      // eslint-disable-next-line no-undef
      printUrl(
        `print2.php?path=z_print2.php` +
          `&id=${$ctrl.uchet.pid}&nscheta=${$ctrl.uchet.nscheta}` +
          `&type=${zay.type}`
      )
    }

    // наследие из старой формы для отчета
    function getSchet(varName) {
      const firma = $ctrl.up.bank
      const ps = $ctrl.up.values[0] // start
      const pe = $ctrl.up.values[$ctrl.up.values.length - 1] // end

      const name = ps.text
      const kolvo = ps.count
      const summa = ps.summ
      const ei = ps.izm
      const nds = ps.nds

      const ename = pe.text
      const ekolvo = pe.count
      const esumma = pe.summ
      const eei = pe.izm
      const ends = pe.nds

      const data = $.fn.dateutils.date2str_ddmmyyyy($ctrl.uchet.data, '.')
      const zakaz = $ctrl.uchet.firm
      const pid = $ctrl.pid
      const nomer = $ctrl.pid
      const fid = $ctrl.fid
      const val = $ctrl.up.currency // валюта

      const kurs = 1

      const usluga = $ctrl.up.type
      const sstr = $ctrl.up.values
        .filter((uv) => uv.count > 0 && uv.num > 1 && uv.num < 10)
        .map(
          (uv, i) =>
            `&${[
              `aename${i + 1}=${encodeURIComponent(uv.text)}`,
              `aekolvo${i + 1}=${encodeURIComponent(uv.count)}`,
              `aesumma${i + 1}=${encodeURIComponent(uv.summ / kurs)}`,
              `aends${i + 1}=${encodeURIComponent(uv.nds)}`,
              `aeei${i + 1}=${encodeURIComponent(uv.izm)}`,
            ].join('&')}`
        )
        .join('')

      const queryParams = {
        _sid: pid,
        _fid: fid,
        _ename: ename,
        _ekolvo: ekolvo,
        _esumma: esumma / kurs,
        _ends: ends,
        _eei: eei,
        _name: name,
        _usluga: usluga,
        _kolvo: kolvo,
        _summa: summa / kurs,
        _firma: firma,
        _ei: ei,
        _data: data,
        _nds: nds,
        _nomer: nomer,
        _val: val,
        _zakaz: zakaz,
        _filer: $ctrl.zay,
      }
      if (varName === 's') {
        // eslint-disable-next-line no-undef
        fprint(`printschet&` + utils.url.buildQuery(queryParams) + sstr)
      } else if (varName === 'sf') {
        // eslint-disable-next-line no-undef
        fprint(`printschet1&` + utils.url.buildQuery(queryParams) + sstr)
      }
    }
  },
}
