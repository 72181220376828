import { settings } from '../app.config'
import { CRUDServiceAPI2 } from './crud-service.class'

export class GroupServiceApiV2 extends CRUDServiceAPI2 {
  baseUrl = `${settings.equipmentApiUrl}`
  subject = 'equipment/groups'

  /**
   * Сохранение данных о текущем элементе
   * @param {} item
   * @returns
   */
  save(item) {
    const url = `${this.baseUrl}/${this.subject}/${item.id}`

    return this.$http.put(
      url,
      this.createFormData(item),
      this.getMultipartHeaders()
    )
  }

  /**
   * Создание форм-даты
   * @param {} item
   * @returns
   */
  createFormData(item) {
    const formData = new FormData()
    formData.append('name', item.name)
    if ('description' in item) {
      formData.append('description', item.description)
    }
    if ('document' in item) {
      formData.append('document', item.document)
    }
    if ('image' in item) {
      formData.append('image', item.image)
    }

    return formData
  }

  /** Получение дерева групп/изделий. */
  getTree() {
    const url = `${this.baseUrl}/${this.subject}/tree`
    return this.$http.get(url).then((resp) => resp.data)
  }

  /** Получение списка групп с изделиями и глубиной вложенности. */
  getWithItems() {
    const url = `${this.baseUrl}/${this.subject}/items`
    return this.$http.get(url).then((resp) => resp.data)
  }
}

/**
 * Сервис для создания дочерних групп.
 */
export class GroupChildrenServiceApiV2 extends GroupServiceApiV2 {
  subject = 'equipment/groups'

  /**
   * Создание дочерней группы.
   * @param {*} group Дочерняя группа
   * @returns {Promise<*>} Дочерняя группа
   */
  saveChild(group) {
    const subjectUrl = `${this.baseUrl}/${this.subject}`
    const url = `${subjectUrl}/${group.parentId}/children`

    return this.$http.post(
      url,
      this.createFormData(group),
      this.getMultipartHeaders()
    )
  }
}
