/* eslint-disable camelcase */
import angular from 'angular'
import { ngOpenDialog, fprint } from './popups'
import { settings } from './app.config'

function date2str(timestamp) {
  const d = new Date(timestamp * 1000)
  return (
    $.fn.str.zp(d.getDate()) +
    '.' +
    $.fn.str.zp(d.getMonth() + 1) +
    '.' +
    d.getFullYear()
  )
}

function str2date(dateStr) {
  const parts = dateStr.split('.')
  if (parts.length !== 3) throw Error('Wrong date format')
  const isoDateStr = parts.reverse().join('/')
  return new Date(isoDateStr).getTime() / 1000
}

;(function ($) {
  if (!$.fn.dateutils) {
    $.fn.dateutils = {}
  }

  $.fn.dateutils = {
    ts2str: date2str,
    date2str_yyyymmdd,
    date2str_ddmmyyyy,
    str2ts: str2date,
    d2ts: date2timestamp,
    ts2d: timestamp2date,
    str_ddmmyyyy2date,
    str_yyyymmdd2date,
    internal2html5,
    html52internal,
    sql2date,
    date2sql,
  }

  /**
   * Конвертация даты в строку вида 2018-05-16
   * @param {Date} date дата
   */
  function date2str_yyyymmdd(date, sep) {
    return [
      date.getFullYear(),
      $.fn.str.zp(date.getMonth() + 1),
      $.fn.str.zp(date.getDate()),
    ].join(sep)
  }

  function date2str_ddmmyyyy(date, sep) {
    return [
      $.fn.str.zp(date.getDate()),
      $.fn.str.zp(date.getMonth() + 1),
      date.getFullYear(),
    ].join(sep)
  }

  function date2timestamp(date) {
    return date.getTime() / 1000
  }

  function timestamp2date(timestamp) {
    return new Date(timestamp * 1000)
  }

  function str_ddmmyyyy2date(s, sep) {
    const p = s.split(sep)
    const year = parseInt(p[2])
    const month = parseInt(p[1]) - 1
    const day = parseInt(p[0])

    return new Date(year, month, day)
  }

  function str_yyyymmdd2date(s, sep) {
    const p = s.split(sep)

    const year = parseInt(p[0])
    const month = parseInt(p[1]) - 1
    const day = parseInt(p[2])

    return new Date(year, month, day)
  }

  /**
   * Конвертация из даты вида '12.04.2015' в '2015-04-12'
   * @param {String} internal
   */
  function internal2html5(internal) {
    const date = $.fn.dateutils.str_ddmmyyyy2date(internal, '.')
    return $.fn.dateutils.date2str_yyyymmdd(date, '-')
  }

  /**
   * Конвертация из даты вида '2015-04-12' в '12.04.2015'
   * @param {String} html5
   */
  function html52internal(html5) {
    const date = $.fn.dateutils.str_yyyymmdd2date(html5, '-')
    return $.fn.dateutils.date2str_ddmmyyyy(date, '.')
  }

  /**
   * Конвертация из даты вида '2015-04-12' в объект Date
   */
  function sql2date(sql) {
    if (!sql) return null
    return $.fn.dateutils.str_yyyymmdd2date(sql, '-')
  }

  /**
   * Конвертация из объекта Date в дату вида '2015-04-12'
   */
  function date2sql(date) {
    if (!date) return null
    return $.fn.dateutils.date2str_yyyymmdd(date, '-')
  }
})(jQuery)
;(function ($) {
  if (!$.fn.str) {
    $.fn.str = {}
  }

  $.fn.str = {
    zeroprefix,
    zp: zeroprefix,
  }

  function zeroprefix(v) {
    let sv = String(v)
    if (sv.length === 1) sv = '0' + sv
    return sv
  }
})(jQuery)

export default {
  arraySort: {
    byGosNomer: function (a, b) {
      return extractDigits(a.nomer) - extractDigits(b.nomer)

      function extractDigits(nomer) {
        const found = /(\d+)/.exec(nomer)
        return found ? parseInt(found[0]) : 0
      }
    },
  },
  obj: {
    equal: (obj1, obj2) => {
      const json1 = angular.toJson(obj1)
      const json2 = angular.toJson(obj2)
      return json1 === json2
    },
    copy: (o) => _.cloneDeep(o),
    pick: (obj, keys, clone = false) => (({ ...keys }) => ({ ...keys }))(obj),
  },
  db: {
    str2int: (s) => {
      const iv = parseInt(s)
      return isNaN(iv) ? 0 : iv
    },
    str2float: (s) => {
      const fv = parseFloat(s)
      return isNaN(fv) ? 0.0 : fv
    },
    str2date: $.fn.dateutils.sql2date,
    date2str: $.fn.dateutils.date2sql,
    dmy2date,
    date2dmy,
  },
  math: {
    round: function (num, precision) {
      return $.fn.roundPHP(num, precision)
    },
  },
  url: {
    buildQuery,
  },
  print: printReport,
  fprint,
  date: {
    today_s: () => {
      const date = new Date()
      return $.fn.dateutils.date2str_ddmmyyyy(date, '.')
    },
  },
  popups: {
    open: ngOpenDialog,
    openWindow: $.fn.openWindow,
    openState,
  },
}

/**
 * Полное имя фирмы.
 *
 * @param {string} name наименование фирмы
 * @param {string} type форма собственности
 * @returns {string} полное имя фирмы
 */
export function quotedName(name, type = '') {
  if (type) {
    type = `${type.trim().toUpperCase()}&nbsp;`
  }
  return `${type}"${name.trim()}";`
}

/**
 * Печать на основе шаблонов PHP Spreadsheet.
 * @param {*} report
 * @param {*} params
 */
function printReport(report, title = 'Отчет', params = {}) {
  params._fname = title
  const args = $.fn.buildQuery(params)
  window.location = `print2.php?path=${report}.php&${args}`
}

function dmy2date(value) {
  if (value.trim() === '') {
    return null
  }
  return $.fn.dateutils.str_ddmmyyyy2date(value, '.')
}

function date2dmy(value) {
  if (!value) {
    return ''
  }
  return $.fn.dateutils.date2str_ddmmyyyy(value, '.')
}

/**
 * Открытие состояния в диалоговом окне.
 *
 * @param {string} state состояние для перехода
 * @param {number} width длина окна (px)
 * @param {number} height высота окна (px)
 * @returns {Promise<object>} результат выхода из диалога
 */
function openState(state, width, height) {
  const dfd = $.Deferred()
  const child = $.fn.openWindow('#!/' + state, width, height)
  child.dialog = dfd
  return dfd.promise()
}

/**
 * Является ли строка целым числом?
 * @param {string} str строка
 * @returns {boolean}
 */
export function isInteger(str) {
  if (typeof str !== 'string') return false
  return !isNaN(str) && !isNaN(parseInt(str))
}

/**
 * Первый день текущего месяца.
 * @param {number|null} month месяц (по умолчанию текущий)
 * @param {boolean} fixedYear год берется из настроек
 * @returns {Date}
 */
export function firstDayOfMonth(month = null, fixedYear = true) {
  const date = new Date()
  let y = date.getFullYear()
  if (fixedYear) {
    y = settings.defaultYear
  }
  const m = month === null ? date.getMonth() : month
  return new Date(y, m, 1)
}

/**
 * Последний день текущего месяца.
 * @param {number|null} month месяц (по умолчанию текущий)
 * @param {boolean} fixedYear год берется из настроек
 * @returns {Date}
 */
export function lastDayOfMonth(month = null, fixedYear = true) {
  const date = new Date()
  let y = date.getFullYear()
  if (fixedYear) {
    y = settings.defaultYear
  }
  const m = month === null ? date.getMonth() : month
  return new Date(y, m + 1, 0)
}

/**
 * Первый день текущего года.
 * @returns {Date}
 */
export function firstDayOfYear() {
  const date = new Date()
  const y = date.getFullYear()
  return new Date(y, 0, 1)
}

/**
 * Последний день текущего года.
 * @returns {Date}
 */
export function lastDayOfYear() {
  const date = new Date()
  const y = date.getFullYear()
  return new Date(y, 11, 31)
}

/** Текущий месяц (начиная с 0) @returns {number} */
export function currentMonth() {
  return new Date().getMonth()
}

;(function ($) {
  $.fn.getFormData = function () {
    const data = {}
    const dataArray = $(this).serializeArray()
    for (let i = 0; i < dataArray.length; i++) {
      data[dataArray[i].name] = dataArray[i].value
    }

    return data
  }

  $.fn.isObject = function (x) {
    // eslint-disable-next-line max-len
    // https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript
    return typeof x === 'object' && x !== null
  }

  $.fn.isNumber = function (x) {
    return typeof x === 'number'
  }

  // with checkboxes & radios
  $.fn.getFullFormData = function () {
    const data = {}
    const dataArray = $(this).serializeArray()

    for (let i = 0; i < dataArray.length; i++) {
      data[dataArray[i].name] = dataArray[i].value
    }

    // checkboxes
    const checkboxes = $(this).find('input[type=checkbox]')
    checkboxes.each((i, cb) => {
      data[cb.name] = $(cb).is(':checked')
    })

    return data
  }

  $.fn.numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  $.fn.urlparam = (name) => {
    const url = window.location.href
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (!results) return ''
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  $.fn.urlparams = () => {
    const sPageURL = window.location.search.substring(1)
    const sURLVariables = sPageURL.split('&')
    let sParameterName
    let i
    const params = {}

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=')

      params[sParameterName[0]] = sParameterName[1]
    }
    return params
  }

  $.fn.assigndef = (value, def) => {
    return value || def
  }

  $.postJSON = function (url, data, callback) {
    return jQuery.ajax({
      type: 'POST',
      url,
      contentType: 'application/json',
      data: $.toJSON(data),
      dataType: 'json',
      success: callback,
    })
  }

  $.setNulls = (obj, keys) => {
    keys.forEach((key) => {
      if (obj[key] === '') obj[key] = null
    })
    return obj
  }

  $.fn.buildQuery = (obj) => {
    const keys = Object.keys(obj)
    const values = keys.map((key) => obj[key])

    return keys
      .map((key, index) => {
        let value = values[index]
        if (value === null) {
          return ''
        }
        value = encodeURIComponent(values[index])
        return `${key}=${value}`
      })
      .filter((s) => s !== '')
      .join('&')
  }

  $.fn.openWindow = (url, w, h) => {
    // диалоговые окна на момент написания данного кода
    // вызываются по-разному, но во всех случаях присутствует
    // url, из которого мы достаем часть, отвечающую за
    // "идентификатор" окна (им выступает имя формы).
    // далее сохраняем его размеры в window.localStorage
    // const wid = parseWindowId(url)
    const wid = null // FIXME
    let wpropname, hpropname

    if (wid) {
      wpropname = `gruz_${wid}_innerWidth`
      hpropname = `gruz_${wid}_innerHeight`
      w = window.localStorage.getItem(wpropname) || w
      h = window.localStorage.getItem(hpropname) || h
    } else {
      // console.error(`Could not parse window id from url: ${url}`)
    }

    const win = window.open(
      url,
      '_blank',
      `height=${h},width=${w},scrollbars=1,resizable=1`
    )
    if (wid) {
      win.addEventListener('beforeunload', saveDimensions)
    }
    return win

    function saveDimensions() {
      window.localStorage.setItem(wpropname, win.innerWidth)
      window.localStorage.setItem(hpropname, win.innerHeight)
    }

    // function parseWindowId(url) {
    //   let matches = url.match(/#!\/([a-z]+)\/.*/)
    //   if (matches && matches.length > 1) {
    //     return matches[1]
    //   } else {
    //     matches = url.match(/\?url=([a-z]+)&?.*/)
    //     if (!matches || matches.length < 2) return null
    //     return matches[1]
    //   }
    // }
  }

  $.fn.openPopup = (id, params, w, h) => {
    const baseUrl = window.location.origin
    const qp = $.fn.buildQuery(params)
    return $.fn.openWindow(`${baseUrl}/popup.php?url=${id}&${qp}`, w, h)
  }

  $.fn.ngopen = (path, w, h) => {
    const baseUrl = window.location.origin
    return $.fn.openWindow(`${baseUrl}/ngopen.php#!${path}`, w, h)
  }

  $.fn.openDialog = (id, params, w, h) => {
    const dfd = $.Deferred()
    const child = $.fn.openPopup(id, params, w, h)
    child.dialog = dfd
    return dfd.promise()
  }

  $.fn.objectFromEntries = (entries) => {
    const obj = {}
    entries.forEach((e) => {
      obj[e[0]] = e[1]
    })
    return obj
  }

  $.fn.range = function* (start, len) {
    for (let i = start; i < start + len; i++) {
      yield i
    }
  }

  $.fn.findMaxProp = (objArray, mapper) => {
    // eslint-disable-next-line no-undef
    return Max.max(objArray.map(mapper))
  }

  $.fn.assert = (condition, msg) => {
    if (condition === false) {
      throw new Error(msg)
    }
  }

  $.fn.ngproxy = (func, args) => {
    setTimeout(() => {
      const ctrl = angular.element('.container').controller()
      if (!ctrl[func]) {
        return
      }
      return ctrl[func].apply(ctrl, args)
    })
  }

  $.fn.ngcall = function (cb, vm = 'vm') {
    const sel = 'div[ng-controller]'
    const scope = angular.element($(sel)[0]).scope()
    const self = scope[vm]
    setTimeout(() => {
      cb.apply(self, [self])
    }, 1)
  }

  $.fn.subObject = (obj, props) => {
    const o = {}
    props.forEach((p) => (o[p] = obj[p]))
    return _.cloneDeep(o)
  }

  // округление до 2 знака после зпт
  $.fn.round2 = (num) => {
    return Math.floor(num * 100) / 100
  }

  $.fn.roundPHP = (num, dec) => {
    const num_sign = num >= 0 ? 1 : -1
    return parseFloat(
      (
        Math.round(num * Math.pow(10, dec) + num_sign * 0.0001) /
        Math.pow(10, dec)
      ).toFixed(dec)
    )
  }

  $.fn.convertCurr = (summ, from, fromKurs, to, toKurs) => {
    if (from === to) return summ
    // сначала в рубль
    if (from !== 'rur') {
      summ *= fromKurs
    }
    // затем в новую валюту
    return summ / toKurs
  }
})(jQuery)

// eslint-disable-next-line no-extend-native
Array.prototype.idlize = function (cb) {
  if (!cb) {
    cb = (elem) => elem.id
  }

  if (this == null) {
    throw new TypeError(' this is null or not defined')
  }

  const O = Object(this)
  const len = O.length >>> 0

  const arr = new Array(len)

  let k = 0
  while (k < len) {
    if (k in O) {
      const elem = O[k]
      arr[cb(elem)] = elem
    }
    k++
  }
  return arr
}

function buildQuery(obj) {
  return $.fn.buildQuery(obj)
}
